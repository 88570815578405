@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: 'Proxima Nova', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e0e1dd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.column {
  float: left;
  width: 30%;  
  padding: 20px;
}

.row {
  margin-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  background-color: whitesmoke;
  border-radius: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.branchTitle{
  font-family: 'Klavika';
  font-size: 25px;
}

.testsTitle{
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Klavika';
  font-size: 28px;
}

.testName {
  margin-bottom: 5px;
  margin-left: 20px;
  font-family: 'Proxima Nova';
  font-size: 12px;
}

.details {
  margin-bottom: 5px;
  margin-left: 20px;
  font-family: 'Proxima Nova';
  font-size: 12px;
}

.pass {
  padding-left: 10px;
  text-align:right;
  color:'black';
  width: 15%;
  height: 30px;
  overflow: hidden;
}

.lastrun {
  padding-left: 10px;
  text-align: left;
  color:'black';
  width: 25%;
  height: 30px;
  overflow: hidden;
}

.fail {
  padding-left: 10px;
  text-align:right;
  width: 15%;
  height: 30px;
   overflow: hidden;
}

.percent {
  padding-left: 10px;
  text-align:right;
  width: 15%;
  height: 30px;
  overflow: hidden;
}

.branch {
  text-align:right;
  padding-left: 10px;
  font-size: 14px;
  width: 20%;
  height: 30px;
  overflow: hidden;
}

.tableHeader {
  padding-left: 10px;
  text-align: right;
  font-family: 'Klavika';
  font-size: 18px;
  font-weight: bold;
}

.tableHeaderLastRun {
  padding-left: 10px;
  text-align: left;
  font-family: 'Klavika';
  font-size: 18px;
  font-weight: bold;
}

.change {
  padding-left: 10px;
  text-align:center;
  width: 20%;
  height: 30px;
  overflow: hidden;
}

.overview-grid-container {
  display: grid;  
  grid-template-columns: auto auto auto auto auto auto;
  padding: 5px;
}

.overview-grid-header-item {
  padding: 5px;
  text-align: right;
  font-family: 'Klavika';
  font-size: 18px;
  font-weight: bold;
}

.overview-grid-item {
  padding: 5px;
  text-align: right;
  font-size: 14px;
}

.productVersion {
  width: 50px; /* Set a fixed width or adjust accordingly */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

